<template>
  <div
    class="co-flex-col pa-4"
  >
    <div
      v-if="selTags && selTags.length"
      class="co-flex-col co-justify-center co-items-center mt-2 mb-6"
    >
      <v-chip-group
        @change="changeTag"
      >
        <v-chip
          v-for="tag in selTags"
          :key="tag"
          :value="tag"
          :outlined="curTag !== tag"
          :color="curTag === tag ? 'primary' : 'blue-grey'"
        >
          {{ tag }}
        </v-chip>
      </v-chip-group>
    </div>
    <v-row :dense="dense">
      <template
        v-if="items.length"
      >
        <v-col
          v-for="(item, i) in items"
          :key="`wk_${i}`"
          cols="12"
          lg="3"
          md="4"
          sm="6"
        >
          <v-hover
            v-slot="{ hover }"
            :disabled="!hovered"
            close-delay="120"
          >
            <v-card
              :elevation="hover ? hoverElevation : normalElevation"
              :class="[round, 'transition-swing']"
              :color="backColor"
              flat
              tile
            >
              <v-img
                :aspect-ratio="ratio"
                :src="item.cover"
                height="inherit"
              >
                <div class="co-flex-col co-justify-between co-h-full">
                  <div
                    class="co-flex-col co-w-full co-flex-grow "
                    @click="toContent(i, item)"
                  />
                  <div
                    v-if="hover"
                    class="co-flex-row co-justify-between co-items-center co-h40 px-4"
                  >
                    <span
                      class="text-body-1 white--text"
                      @click="toContent(i, item)"
                    >
                      {{ item.title }}
                    </span>
                    <div class="co-flex-row co-items-center">
                      <!-- <v-icon
                        size="20"
                        color="white"
                      >
                        {{ icos.like }}
                      </v-icon>
                      <span class="text-body-1 white--text ml-1">
                        {{ item.likesNum }}
                      </span> -->

                      <v-icon
                        size="20"
                        color="white"
                        class="ml-3"
                        @click="toAlbum(i, item)"
                      >
                        {{ icos.album }}
                      </v-icon>
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="12"
        >
          <div class="co-flex-col co-justify-center co-items-center pa-6">
            <span
              class="co-text-md co-text-gray-500"
            >
              暂无入选作品
            </span>
          </div>
        </v-col>
      </template>
    </v-row>

    <div
      v-if="pagination.length"
      class="d-flex flex-row mt-6 mb-8"
    >
      <v-pagination
        v-model="pagination.current"
        :length="pagination.length"
        :total-visible="pagination.totalVisible"
        @input="changePagination($event)"
      />
    </div>

    <my-works-albums ref="dlgAlbums" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  // import app from '@/api/co.app'
  import web from '@/api/web/co.web'

  import mixPreset from '@/pages/mixins/activity/mix.activity.preset'

  const parts = mixPreset.DetailParts
  const names = mixPreset.DetailNames
  const part = parts[names.CHOSEN]

  export default {
    components: {
      MyWorksAlbums: () => import('@/pages/sections/my/MyWorksAlbums.vue')
    },
    props: {
      activityId: {
        type: String,
        default: ''
      },
      tags: {
        type: String,
        default: ''
      },
      backColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      dense: {
        type: Boolean,
        default: true
      },
      perColumns: {
        type: [Number, String],
        default: 3
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      }
    },
    data () {
      return {
        name: part.name,
        label: part.label,
        title: part.title,
        total: 0,
        type: part.type,
        ratio: part.ratio,
        params: null,
        items: [],
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 7
        },
        btnDel: {
          icon: 'mdi-close'
        },
        icos: {
          like: 'mdi-thumb-up-outline',
          album: 'mdi-folder-plus-outline'
        },
        dlgAlbum: {
          title: '添加到影集',
          visible: false,
          width: '450px'
        },
        perCols: {
          c1: {
            sm: 12,
            md: 12
          },
          c2: {
            sm: 12,
            md: 6
          },
          c3: {
            sm: 6,
            md: 4
          },
          c4: {
            sm: 6,
            md: 3
          },
        },
        selTags: [],
        curTag: ''
      }
    },
    computed: {
      smCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.sm
      },
      mdCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.md
      },
    },
    watch: {
      activityId: {
        handler (val) {
          this.configParams(val)
          // if (val) {
          //   this.loadContents(true)
          // }
        },
        immediate: true
      },
      tags: {
        handler (val) {
          this.selTags = web.comm.parseTags(val, false)
          this.changeTag('')
        },
        immediate: true
      }
    },
    created () {
    },
    methods: {
      changeTag (val) {
        // console.log({ val })
        this.curTag = val || ''
        let activityTag = this.curTag

        if (activityTag === '全部') {
          activityTag = ''
        }
        this.params.data.activityTag = activityTag
        this.params.data.currentPage = '1'
        this.params.reload = true
        api.httpx.getItems(this.params)
      },
      toAlbum (index, item) {
        const dlg = this.$refs['dlgAlbums']
        if (dlg) {
          dlg.show(item)
        }
      },
      toContent (index, item) {
        const params = {
          enrollId: item.enrollId,
          type: item.type,
          title: item.title
        }

        api.page.navigate(this, 'activityEnroll', params)
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)
      },
      configImages (index, item) {
        const thumbs = item.thumbs || []
        const dst = {
          key: `img-${index}-`,
          enrollId: item.enrollId,
          activityId: item.activityId,
          title: item.title,
          cover: item.cover || thumbs[0],
          type: item.type,
          likesNum: item.likesNum,
          collectNum: item.collectNum,
          hitNum: item.hitNum,
          forwardedNum: item.forwardedNum,
          commentNum: item.commentNum
        }
        this.items.push(dst)
      },
      configVideos (index, item) {
        const dst = {
          key: `vid-${index}-`,
          enrollId: item.enrollId,
          activityId: item.activityId,
          title: item.title,
          cover: item.cover,
          type: item.type,
          likesNum: item.likesNum,
          collectNum: item.collectNum,
          hitNum: item.hitNum,
          forwardedNum: item.forwardedNum,
          commentNum: item.commentNum
        }

        this.items.push(dst)
      },
      configArticles (index, item) {
        const dst = {
          key: `art-${index}-`,
          enrollId: item.enrollId,
          activityId: item.activityId,
          title: item.title,
          cover: item.cover,
          type: item.type,
          likesNum: item.likesNum,
          collectNum: item.collectNum,
          hitNum: item.hitNum,
          forwardedNum: item.forwardedNum,
          commentNum: item.commentNum
        }

        this.items.push(dst)
      },
      configItems () {
        this.items = []
        for (const i in this.params.items) {
          const src = this.params.items[i] || {}
          if (src.type === 'image') {
            this.configImages(i, src)
          } else if (src.type === 'video') {
            this.configVideos(i, src)
          } else if (src.type === 'article') {
            this.configArticles(i, src)
          }
        }
      },
      configParams (activityId) {
        const me = this
        if (me.params) {
          me.params.data.activityId = activityId
        } else {
          const executed = function (res) {
            if (res.status) {
              me.total = res.total
              const data = me.params.data
              const pagination = me.pagination
              const pageSize = parseInt(data.pageSize) || 9
              pagination.length = Math.ceil(res.total / pageSize)
              me.configItems()
            }
          }

          me.params = web.activityEnroll.getParams({
            activityId,
            pageSize: '24',
            state: web.comm.EnrollStates.PASS,
            executed
          })
        }
      },
      loadContents (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
    }
  }
</script>
